import { UserRoles } from "@/enums/UserRoles";

export const internalUserRoleSelect = [
  {
    label: "Super Admin",
    value: UserRoles.superAdmin,
  },
  {
    label: "Customer Service Admin",
    value: UserRoles.customerServiceAdmin,
  },
  {
    label: "Supply Chain Admin",
    value: UserRoles.purchaseTeamAdmin,
  },
  {
    label: "Account Manager",
    value: UserRoles.accountManager,
  },
  {
    label: "Costing Engineer",
    value: UserRoles.costingEngineer,
  },
  {
    label: "Quote Authorizer",
    value: UserRoles.quoteAuthorizer,
  },
  {
    label: "Quote Viewer",
    value: UserRoles.quoteViewer,
  },
  {
    label: "Engineering Team",
    value: UserRoles.engineeringTeam,
  },
  {
    label: "Technical Feedback Viewer",
    value: UserRoles.technicalFeedbackViewer,
  },
  {
    label: "Discounts Editor",
    value: UserRoles.discountEditor,
  },
  {
    label: "Costing Viewer",
    value: UserRoles.costingViewer,
  },
  {
    label: "Costing Editor",
    value: UserRoles.costingEditor,
  },
  {
    label: "Costing Parameters Viewer",
    value: UserRoles.costingParametersViewer,
  },
  {
    label: "Costing Parameters Editor",
    value: UserRoles.costingParametersEditor,
  },
  {
    label: "Download Engineering Reports",
    value: UserRoles.downloadEngineeringReports,
  },
  {
    label: "Internal Bid Viewer",
    value: UserRoles.internalBidViewer,
  },
  {
    label: "Internal Bid Editor",
    value: UserRoles.internalBidEditor,
  },
];
