import { UserRoles } from "@/enums/UserRoles";

export const mfrUserRoleSelect = [
  {
    label: "Mfr Bid Viewer",
    value: UserRoles.mfrBidViewer,
  },
  {
    label: "Mfr Bid Authorizer",
    value: UserRoles.mfrBidAuthorizer,
  },
  {
    label: "Mfr Costing Viewer",
    value: UserRoles.mfrCostingViewer,
  },
];
