import { internalUserRoleSelect } from "@/constants/user/internalUserRoleSelect";
import { mfrUserRoleSelect } from "@/constants/user/mfrUserRoleSelect";
import { UserRoles } from "@/enums/UserRoles";

export const getUserRoleLabel = (userRole: UserRoles): string => {
  const role = [...internalUserRoleSelect, ...mfrUserRoleSelect].find(
    (item) => item.value === userRole
  );
  return role?.label || userRole;
};
